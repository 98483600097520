.section4 {
  height: 1px;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 100px 0 0 0;
}

.h2 {
  margin: 0 0 20px 0;
  font-size: 25px;
  color: #d2b98d;
}

.h3 {
  margin: 10px;
  font-size: 30px;
}

.p {
  display: inline-block;
  width: 80%;
  max-width: 1000px;
}

.services {
  display: flex;
  width: 80vw;
  max-width: 1000px;
  margin-top: 20px;
}

.ul {
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
  padding: 0;
  list-style-type: none;
}

.li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 220px;
  height: 33px;
  margin: 10px;
}

.li div {
  display: flex;
  align-items: center;
  width: 230px;
}

.span {
  margin: 4px 5px;
}

.line {
  width: 100%;
  height: 1px;
  margin: 0;
  background-color: #c7bfbf;
}

.handsImg {
  object-fit: contain;
}

@media screen and (max-width: 1107px) {
  .services {
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 713px;
  }

  .ul {
    justify-content: space-around;
  }

  .handsImg {
    display: none;
  }
}

@media screen and (max-width: 599px) {
  .services {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .ul {
    justify-content: center;
  }
}
