.footer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 130px;
  margin: 60px 0 0 0;
  padding: 0;
  background-color: #352219;
  color: #fff;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 145px;
  margin: 0 20px 0 30px;
  border: 1px solid #fff;
}

.residencia {
  margin: 0;
  font-size: 25px;
}

.line {
  width: 131px;
  height: 1px;
  margin: 0;
  background-color: #fff;
}

.plaza {
  margin: -7px 0 0 0;
  font-size: 40px;
}
