.section5 {
  height: 1px;
}

.section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 100px 0 20px 0;
}

.map {
  width: 40%;
  height: 566px;
  border: 1px solid #d2b98d;
}

.h2 {
  margin: 20px auto;
  font-size: 25px;
  color: #d2b98d;
}

.h3 {
  margin: 10px;
  font-size: 30px;
}

.infoSuperContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 40%;
  height: 530px;
  padding: 20px;
  background-color: #f2f3f0;
}

.infoContainer {
  display: flex;
  align-items: center;
  margin: 5px 10px;
}

.img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
}

.infoContainer p {
  margin-left: 10px;
}

.form {
  display: flex;
  flex-direction: column;
}

.input {
  background-color: inherit;
  border: none;
  border-bottom: 1px solid #6e6b6b;
  margin: 10px;
  padding: 10px;
}

.p {
  margin: 10px;
}

.textarea {
  margin: 10px;
}

.submit {
  margin: 10px;
  width: 140px;
  height: 50px;
  border: 1px solid #352219;
}

@media screen and (max-width: 800px) {
  .section {
    flex-direction: column-reverse;
  }

  .map {
    width: 70vw;
    min-width: 360px;
    height: 70vw;
    border: none;
  }

  .infoSuperContainer {
    justify-content: space-around;
    width: 70vw;
    min-width: 360px;
    height: 70vw;
    min-height: 550px;
    box-sizing: border-box;
  }
}
