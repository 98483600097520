.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 40px;
  box-sizing: border-box;
}

.headerInvisible {
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.5s ease;
  color: #fff;
}

.headerVisible {
  background-color: #fff;
  transition: background-color 0.5s ease;
  color: #352219;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 67px;
  width: 115px;
  cursor: pointer;
}

.boxInvisible {
  border: 1px solid #fff;
}

.boxVisible {
  border: 1px solid #352219;
}

.residencia {
  margin: 0;
  font-size: 20px;
}

.line {
  width: 101px;
  height: 1px;
  margin: 0;
}

.plaza {
  margin: -7px 0 0 0;
  font-size: 30px;
}

.lineInvisible {
  background-color: #fff;
}

.lineVisible {
  background-color: #352219;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  min-width: 750px;
  font-size: 16px;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  font-size: 24px;
}

.hamburger {
  display: none;
}

.hamburgerInvisible {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(219deg) brightness(106%) contrast(101%);
}

.hamburgerVisible {
  filter: invert(11%) sepia(50%) saturate(536%) hue-rotate(335deg) brightness(95%) contrast(91%);
}

.phone {
  display: flex;
  align-items: center;
  font-size: 24px;
}

.phoneImg {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.phonetxt {
  margin: 0;
}

.contact {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
}

.contactInvisible {
  border: 1px solid #fff;
}

.contactVisible {
  background-color: #352219;
  color: #fff;
}

.emailImg {
  width: 20px;
  height: 15px;
  margin-right: 5px;
}

.spaceDiv {
  display: none;
  width: 15vw;
}

@media screen and (max-width: 1400px) {
  .header {
    justify-content: space-between;
  }

  .a,
  .phone {
    font-size: 18px;
  }
}

@media screen and (max-width: 1085px) {
  .hamburger {
    display: inline-block;
    cursor: pointer;
    width: 40px;
  }

  .hamburgerInvisible {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(219deg) brightness(106%) contrast(101%);
  }

  .navHamburger {
    filter: invert(11%) sepia(50%) saturate(536%) hue-rotate(335deg) brightness(95%) contrast(91%);
  }

  .nav {
    flex-direction: column;
    position: fixed;
    justify-content: space-around;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    padding: 40px 0 0 0;
    min-width: 0;
    background-color: #fff;
    color: #352219;
  }

  .spaceDiv {
    display: inline-block;
  }

  .none {
    opacity: 0;
    visibility: hidden;
    transition:
      opacity 0.5s ease-in-out,
      visibility 0s linear 0.5s;
  }

  .a,
  .phone {
    font-size: 24px;
  }

  .phoneImg {
    display: none;
  }

  .contact {
    display: inline-block;
    padding: 0;
    border: none;
    background-color: inherit;
    color: #352219;
  }

  .emailImg {
    display: none;
  }
}
