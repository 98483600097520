.section3 {
  height: 1px;
}

.section {
  text-align: center;
  margin-top: 100px;
}

.h2 {
  margin: 0 0 20px 0;
  font-size: 25px;
  color: #d2b98d;
}

.h3 {
  margin: 10px 0;
  font-size: 30px;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pContainer {
  width: 80%;
  max-width: 500px;
  margin: 30px 0;
  font-size: 16px;
}

.img {
  width: 70vw;
  max-width: 500px;
  height: 80vw;
  max-height: 700px;
}
