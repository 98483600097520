.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.arrow {
  position: absolute;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 47%;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.arrow:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

.arrowLeft {
  left: 15px;
}

.arrowRight {
  right: 15px;
}

.img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  left: 0;
  top: 0;
}

.arrowImg {
  height: 24px;
  width: 24px;
}

.active {
  z-index: 0;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.none {
  z-index: -10;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
