.main_background {
  position: relative;
  background-image: url('../../../public/images/main_background.jpg');
  width: 100%;
  max-height: 600px;
  min-height: 470px;
  height: 50vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #fff;
}

.main_background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.main_background_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
  min-width: 280px;
}

.main_background h1 {
  display: inline-block;
  width: 40vw;
  min-width: 370px;
  margin-top: 100px;
  font-size: 30px;
  z-index: 2;
}

.main_background p {
  display: inline-block;
  width: 40vw;
  min-width: 370px;
  font-size: 16px;
  z-index: 2;
}

.welcomeSection {
  margin: 0 20px;
}

.welcomeSuperContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1168px;
  max-height: 640px;
  height: 50vw;
  margin: 60px auto 0;
}

.welcomeContainer {
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;
  background-color: #f3f2f0;
}

.welcomeSubContainer {
  display: flex;
  width: 100%;
  height: 80%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

.welcomeimg {
  width: 50%;
  height: 100%;
  max-width: 584px;
  max-height: 640px;
  object-fit: cover;
}

.welcomeContainer h2 {
  margin: 10px;
  font-size: 40px;
  color: #d2b98d;
}

.welcomeContainer p {
  margin: 5px 40px;
  font-size: 16px;
}

.linescontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.line {
  display: inline-block;
  width: 130px;
  height: 1px;
  margin: 20px;
  background-color: #918985;
}

.textimg {
  width: 7vw;
  max-width: 64px;
  min-width: 40px;
}

@media screen and (max-width: 850px) {
  .welcomeSuperContainer {
    flex-direction: column-reverse;
    height: auto;
    max-height: 800px;
  }

  .welcomeContainer {
    min-width: 365px;
    width: 80%;
    height: 600px;
    text-align: center;
    background-color: #f3f2f0;
  }

  .welcomeSubContainer {
    width: 100%;
    min-height: 400px;
  }

  .welcomeimg {
    width: 80%;
    min-width: 365px;
    max-width: 648px;
    height: auto;
    max-height: 350px;
  }
}
