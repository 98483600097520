.section2 {
  height: 1px;
  margin: 0;
}

.section {
  text-align: center;
  margin: 100px 0 0 0;
}

.h2 {
  margin: 0 0 20px 0;
  font-size: 25px;
  color: #d2b98d;
}

.h3 {
  margin: 10px 0;
  font-size: 30px;
}

.installationsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
}

.installationsText {
  width: 40%;
  max-width: 500px;
  min-width: 460px;
}

.installationsText p {
  margin: 0 0 40px 0;
}

.reelContainer {
  margin: 0 40px;
  width: 350px;
  height: 450px;
}

.installationsImg {
  width: 100%;
  height: 100%;
  min-width: 266px;
}

.handsimg {
  width: 100%;
  height: 180px;
  margin-top: 50px;
  object-fit: cover;
}

@media screen and (max-width: 850px) {
  .installationsContainer {
    flex-direction: column;
  }

  .installationsText {
    width: 80%;
    min-width: 300px;
  }
}
